<script setup lang="ts">
import { entries } from 'lodash'
// Types.
//
import type { BookingListPassenger } from '~/stores/lib/typings'
// Properties and events.
//
const props = defineProps<{
  passengers: BookingListPassenger[]
  byAge?: boolean
}>()
// Main variables.
//
const passengers = toRef(props, 'passengers')
const byAge = toRef(props, 'byAge')
const children: any = ref([])
const adults: any = ref([])
// Functions.
//
const processPassengers = function processPassengers() {
  const d = new Date()
  const targetDate = d.setFullYear(d.getFullYear() - 15)
  const activePassengers = passengers.value.filter(
    (p: BookingListPassenger) => p.cancelledAt === null,
  )
  entries(activePassengers).forEach((entry) => {
    const [, value] = entry
    if (new Date(targetDate) < new Date(value.dateOfBirth)) {
      children.value.push(value.dateOfBirth)
    } else {
      adults.value.push(value.dateOfBirth)
    }
  })
}

onMounted(() => {
  if (byAge.value) {
    processPassengers()
  }
})
</script>

<template>
  <div class="rk-cmp-passenger-count">
    <div v-if="byAge">
      <p v-if="adults" class="m-0">
        {{ adults.length }}
        Adult<span v-if="adults.length > 1">s</span>
      </p>
      <p v-if="children" class="m-0">
        {{ children.length }}
        <span v-if="children.length === 1">Child</span>
        <span v-else>Children</span>
      </p>
    </div>
    <div v-else>
      <p v-if="passengers" class="m-0">
        {{ passengers.filter((p) => p.cancelledAt === null).length }}
      </p>
    </div>
  </div>
</template>

<style lang="scss"></style>
