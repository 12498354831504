<script setup lang="ts">
// Types.
//
import { BookingStatus } from '~/apollo/types'
import type { DateItem } from '~/stores/lib/typings'
// Properties and events.
//
const props = defineProps<{
  dates: DateItem[]
}>()
// Main variables.
//
const dates = toRef(props, 'dates')
// Computed variables.
//
const serviceDateStart = computed(() => {
  return dates.value
    .filter(
      (r: any) =>
        r.status === BookingStatus.Confirmed && r.serviceStart !== null,
    )
    .map((reservation: any) => reservation.serviceStart)
    .sort()
    .slice(0, 1)
})

const serviceDateEnd = computed(() => {
  return dates.value
    .filter(
      (r: any) =>
        r.status === BookingStatus.Confirmed && r.serviceStart !== null,
    )
    .map((reservation: any) => reservation.serviceEnd)
    .sort()
    .reverse()
    .slice(0, 1)
})
</script>

<template>
  <div v-if="serviceDateStart[0] && serviceDateEnd[0]">
    <p class="m-0">
      {{ useFormat(new Date(serviceDateStart[0]), 'dd MMM yyyy') }}
    </p>
    <p class="m-0">
      {{ useFormat(new Date(serviceDateEnd[0]), 'dd MMM yyyy') }}
    </p>
  </div>
  <p v-else class="m-0">N/A</p>
</template>

<style lang="scss"></style>
