<template>
  <tr
    ref="tableRow"
    v-for="(booking, i) in bookings"
    :key="`booking-${i}`"
    :class="{ 'rk-disabled': excludeIds && excludeIds.includes(booking.id) }"
    @click="tableRowClick(booking.id, i)"
  >
    <td>
      <BookingStatus :status="booking.status" />
    </td>
    <td class="table-cell-mobile">
      <span class="font-monospace">
        {{ booking.reference }}
      </span>
      <span class="d-inline-block d-lg-none ms-2">
        - {{ booking.description }}
      </span>
    </td>
    <td>
      {{ booking.description }}
    </td>
    <td>
      <div
        v-show="
          booking.totalPrice.amount &&
          booking.totalPrice.amount > 0 &&
          booking.totalPrice.currency
        "
      >
        <p class="mb-1">
          {{
            mixin.rkPriceFormat(
              booking.totalPrice.amount,
              booking.totalPrice.currency,
              0,
            )
          }}
        </p>
        <p class="m-0">
          {{ booking.totalPrice.currency }}
        </p>
      </div>
    </td>
    <td>
      <div v-if="booking.customer">
        <p>
          {{ booking.customer.name }}
        </p>
        <p>
          {{ booking.customer.email }}
        </p>
      </div>
    </td>
    <td>
      {{ useFormat(new Date(booking.createdAt), 'dd/MM/yyyy hh:mm:aaa') }}
    </td>
    <td>
      <PassengerCount :passengers="booking.passengers" />
    </td>
    <td>
      <ServiceDates :dates="booking.productReservations" />
    </td>
    <td>
      <p v-if="booking.totalPrice.currency" class="m-0">
        Paid:
        {{
          mixin.rkPriceFormat(
            booking.totalPrice.amount - booking.outstandingBalance.amount,
            booking.totalPrice.currency,
            0,
          )
        }}
      </p>
      <p
        v-if="booking.outstandingBalance.currency"
        class="rk-text rk-text--success rk-semi-bold m-0"
      >
        Outstanding:
        {{
          mixin.rkPriceFormat(
            booking.outstandingBalance.amount,
            booking.outstandingBalance.currency,
            0,
          )
        }}
      </p>
    </td>
    <TableInteractiveCell
      :cell-id="booking.id"
      :cell-index="i"
      :list-type="listType || null"
      list-button-text="Select"
      list-button-icon="icon-sys-add-booking"
    />
    <td ref="tablePanel" class="table-panel d-lg-none">
      <div class="table-panel-container" @click.stop>
        <TableMobileContent title="Status">
          <BookingStatus :status="booking.status" />
        </TableMobileContent>
        <TableMobileContent title="Reference">
          <span class="font-monospace">
            {{ booking.reference }}
          </span>
        </TableMobileContent>
        <TableMobileContent title="Booking Name">
          {{ booking.description }}
        </TableMobileContent>
        <TableMobileContent title="Price">
          <div
            v-show="
              booking.totalPrice.amount &&
              booking.totalPrice.amount > 0 &&
              booking.totalPrice.currency
            "
          >
            <p class="mb-1">
              {{
                mixin.rkPriceFormat(
                  booking.totalPrice.amount,
                  booking.totalPrice.currency,
                  0,
                )
              }}
            </p>
            <p class="m-0">
              {{ booking.totalPrice.currency }}
            </p>
          </div>
        </TableMobileContent>
        <TableMobileContent title="Booking Contact">
          <div v-if="booking.customer">
            <p>
              {{ booking.customer.name }}
            </p>
            <p>
              {{ booking.customer.email }}
            </p>
          </div>
        </TableMobileContent>
        <TableMobileContent title="Date Created">
          {{ useFormat(new Date(booking.createdAt), 'dd/MM/yyyy hh:mm:aaa') }}
        </TableMobileContent>
        <TableMobileContent title="Pax">
          <PassengerCount :passengers="booking.passengers" />
        </TableMobileContent>
        <TableMobileContent title="Start/End">
          <ServiceDates :dates="booking.productReservations" />
        </TableMobileContent>
        <TableMobileContent title="Balances">
          <div>
            <p v-if="booking.totalPrice.currency" class="m-0">
              Paid:
              {{
                mixin.rkPriceFormat(
                  booking.totalPrice.amount - booking.outstandingBalance.amount,
                  booking.totalPrice.currency,
                  0,
                )
              }}
            </p>
            <p
              v-if="booking.outstandingBalance.currency"
              class="rk-text rk-text--success rk-semi-bold m-0"
            >
              Outstanding:
              {{
                mixin.rkPriceFormat(
                  booking.outstandingBalance.amount,
                  booking.outstandingBalance.currency,
                  0,
                )
              }}
            </p>
          </div>
        </TableMobileContent>
        <div class="text-center">
          <Btn
            v-if="listType"
            :id="`customButton-${i}`"
            style-opts="rk-btn--light rk-btn--text-icon"
            @process:click="tableRowClick(booking.id, i, true)"
          >
            <i class="rk-icon rk-icon--text-sm fontello icon-link me-2"></i>
            Link
          </Btn>
          <Btn
            v-else
            :id="`customButton-${i}`"
            :link="true"
            :path="`/bookings/${booking.id}`"
            style-opts="rk-btn--light rk-btn--text-icon"
          >
            <i class="rk-icon rk-icon--text-sm fontello icon-sys-edit"></i>
            Edit
          </Btn>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { mixin } from '~/mixins/global'

export default defineComponent({
  props: {
    bookings: {
      type: Object as PropType<any> | any,
    },
    excludeIds: {
      type: Array as PropType<string[]>,
    },
    listType: {
      type: String as PropType<string> | any,
    },
  },
  computed: {
    mixin() {
      return mixin
    },
  },
  emits: ['process:booking-id'],
  methods: {
    isExcluded(id: string) {
      return this.excludeIds && this.excludeIds.includes(id)
    },
    async tableRowClick(id: string, panel: number, processEvent?: boolean) {
      const winWidth = window.innerWidth
      if (winWidth <= 992) {
        if (this.listType && processEvent) {
          if (!this.isExcluded(id)) this.$emit('process:booking-id', id)
        } else {
          const tablePanel = this.$refs.tablePanel as HTMLElement
          const tableRow = this.$refs.tableRow as HTMLElement
          mixin.rkIndexClassToggle(tablePanel, 'show', panel)
          mixin.rkIndexClassToggle(tableRow, 'active', panel)
        }
      } else {
        if (this.listType) {
          if (!this.isExcluded(id)) this.$emit('process:booking-id', id)
        } else {
          await navigateTo(`/bookings/${id}`)
        }
      }
    },
  },
})
</script>

<style></style>
